import React from "react"

import Layout from "../components/Layout/Layout"
import Hero from "../components/Home/Hero/Hero"
import Content from "../components/Home/Content/Content"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { reCaptchaKey } from "./../components/Variables"


import "../../i18n"
import i18n from "i18next"

const IndexPage = () => {
  i18n.changeLanguage("pl")

  return (
    <Layout
      seo={{
        title: "Darmowy audyt SEO Twojej strony WWW  • WeNet",
        description:
          "Skorzystaj z darmowego audytu SEO i zleć optymalizację swojej strony agencji WeNet! • Pomożemy Ci dopasować stronę do wymogów Google",
        hreflangs: {
          pl: "",
          en: "en/",
          de: "de/",
        },
      }}
      isHome={true}
    >
      <>
        <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
          <Hero />
        </GoogleReCaptchaProvider>
        <Content />
      </>
    </Layout>
  )
}

export default IndexPage
